<i18n>
  {
    "de": {
      "newPortfolio": "Neues Portfolio",
      "noPortfolios": "Keine Portfolios",
      "loading": "Daten werden geladen",
      "buildingsCount": "Liegenschaft | Liegenschaften",
      "simulationPending": "{n} in Berechnung"
    }
  }
</i18n>

<template>
  <li
    class="c-portfolio-intro-list-item"
    @mouseenter="showSummaryInfoBox = true"
    @mouseleave="showSummaryInfoBox = false"
  >
    <component
      :is="isActive(portfolio) ? 'router-link' : 'div'"
      class="item"
      :to="{ name: 'portfolioBuildings', params: { portfolio_id: portfolio.id } }"
    >
      <section>
        <header>
          <div class="portfolio-id">{{ portfolio.name }}</div>
        </header>
        <main>
          <div class="portfolio-description">
            {{ portfolio.summary?.total }} {{ $tc('buildingsCount', portfolio.summary?.total) }}
            <div class="portfolio-summary-info-box">
              <transition name="fade">
                <SummaryInfoBox v-if="showSummaryInfoBox" :summaryData="portfolio.summary" />
              </transition>
            </div>
          </div>
        </main>
        <footer v-if="buildingSimulationQueue(portfolio.id).length > 0">
          <div class="portfolio-intro-list-item-queue">
            <BuildingQueuedIndicator />
            <span>{{ $tc('simulationPending', this.buildingSimulationQueue(portfolio.id).length) }}</span>
          </div>
        </footer>
      </section>
    </component>
  </li>
</template>

<script>
import { mapGetters } from 'vuex'

import BuildingQueuedIndicator from '@/components/shared/BuildingQueuedIndicator.vue'
import SummaryInfoBox from '../portfolio/SummaryInfoBox.vue'

export default {
  components: {
    BuildingQueuedIndicator,
    SummaryInfoBox,
  },

  props: {
    portfolio: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      showSummaryInfoBox: false,
    }
  },

  computed: {
    ...mapGetters({
      buildingSimulationQueue: 'queue/buildingSimulationQueue',
    }),
  },

  methods: {
    isActive(portfolio) {
      if (portfolio.inactive_after === null) {
        return true
      } else {
        const inactive = new Date(Date.parse(portfolio.inactive_after))
        inactive.setHours(23, 59, 59, 999)
        return new Date() <= inactive
      }
    },
  },
}
</script>

<style lang="scss">
.c-portfolio-intro-list-item {
  display: contents;

  header,
  main,
  footer {
    padding: var(--box-padding);
  }

  header {
    border-bottom: var(--box-border);
  }

  main {
    display: flex;
    flex-direction: row;
  }

  footer {
    align-self: end;
    border-top: var(--box-border);
    font-weight: 400;

    ul {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      font-size: var(--font-xs);
    }

    ul ~ ul {
      margin-top: var(--spacing-xs);
    }
  }

  > .item {
    display: grid;
    grid-template-rows: auto auto 1fr;

    > section {
      display: contents;
    }
  }

  > div.item {
    opacity: 0.5;
  }

  & .portfolio-id {
    font-weight: 700;
  }

  & .portfolio-description {
    display: flex;
    flex-direction: row;
    /* justify-content: space-between; */
    gap: var(--spacing-xs);
    width: 100%;
    font-size: var(--font-s);
    font-weight: 400;
  }

  & .portfolio-summary-info-box {
    position: relative;
    top: -1px;
    justify-self: end;
  }

  & .portfolio-intro-list-item-queue {
    display: flex;
    gap: 5px;
    align-items: baseline;
  }
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s ease;
}
.fade-enter, .fade-leave-to /* .fade-leave-active in <2.1.8 */ {
  opacity: 0;
}
</style>
